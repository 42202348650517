import React, { useState } from "react"
import Layout from "../../../components/Layout"
import SEO from "../../../components/SEO/SEO"
import NavBar from "../../../components/NavBar/NavBar"
import SeeAlso from "../../../components/SeeAlso/SeeAlso"
import {
  binaryToDecimal,
  calculateBinary,
  decimalToBinary,
  isBinary,
} from "../../../components/Script/binary"
import { isSSR } from "../../../components/Script/script"

import {
  Title,
  Input,
  Select,
  Control,
  StaticButton,
  Columns,
  Column,
  Field,
} from "../../../StyleComponents/styles"
import {
  FlexDiv,
  InputButton,
  KeyboardButton,
} from "../../../StyleComponents/pagesStyle"

const GoogleAds = React.lazy(() => import("../../../components/ADS/ADS"))

const navRoadArray = [
  { name: "Calculator", link: "/calculator/" },
  { name: "Binary Calculator", link: "/binary-calculation/" },
]
const seeAlsoArray = [
  "/binary-decoder",
  "/binary-encoder",
  "/ascii-and-binary-converter",
  "/decimal-hexadecimal-binary-converter",
]

function BinaryCalculator(props) {
  let [firstValue, setfirstValue] = useState("")
  let [secondValue, setsecondValue] = useState("")
  let [operator, setoperator] = useState("")

  let [operatorOption, setoperatorOption] = useState("+")
  let [valueOne, setValueOne] = useState("1010")
  let [valueTwo, setValueTwo] = useState("1001")

  let [binaryToD, setbinaryToD] = useState("")
  let [decimalFromB, setdecimalFromB] = useState("")

  let [decimaltoB, setdecimaltoB] = useState("")
  let [binaryFromD, setbinaryFromD] = useState("")

  let [screen, setScreen] = useState("")

  function convertBinaryToD(e) {
    setbinaryToD(e.target.value)
    if (isBinary(e.target.value) && e.target.value !== "") {
      setdecimalFromB(binaryToDecimal(e.target.value))
    } else {
      setdecimalFromB("")
    }
  }

  function convertDecimalToB(e) {
    setdecimaltoB(e.target.value)
    if (e.target.value !== "") {
      setbinaryFromD(decimalToBinary(e.target.value))
    } else {
      setbinaryFromD("")
    }
  }

  function changeOperator(e) {
    setoperatorOption(e.target.value)
  }

  function addNumber(number) {
    if (!operator) {
      setfirstValue(num => num + number)
    } else if (screen !== "") {
      let newScreen = screen
      clearDisplay()
      setfirstValue(newScreen + number)
    } else {
      setsecondValue(num => num + number)
    }
  }
  function addOperator(oper) {
    if (!operator) {
      setoperator(oper)
    } else if (screen !== "") {
      let newScreen = screen
      clearDisplay()
      setfirstValue(newScreen)
      setoperator(oper)
    }
  }

  function calculate(e) {
    setScreen(calculateBinary(firstValue, secondValue, operator))
  }
  function clearDisplay() {
    setScreen("")
    setfirstValue("")
    setsecondValue("")
    setoperator("")
  }

  return (
    <Layout location={props.location}>
      <SEO
        title="Binary Calculator"
        description="The binary calculation, simple fast calculate binary adding, dividing, multiplying, and subtracting fractions step by step."
        keywords={[
          "Binary Calculator, calculate binary, adding binary, dividing binary, multiplying binary, subtracting binary, multiplying and subtracting binary, binary fractions, binary calc, calculate binary online.",
        ]}
      />
      <NavBar listPages={navRoadArray} />
      <div className="container">
        <Title>Binary Calculator</Title>
        <Columns>
          <Column>
            <Field>
              <Control className="expandedWidth">
                <Input
                  className="borderedLeft"
                  type="number"
                  min="0"
                  value={valueOne}
                  onChange={e => setValueOne(e.target.value)}
                />
              </Control>
              <Control>
                <Select borderRadius="0" width="70px">
                  <select
                    aria-label="Text-align"
                    onChange={changeOperator}
                    onBlur={changeOperator}
                    value={operatorOption}
                  >
                    <option value="+">+</option>
                    <option value="-">-</option>
                    <option value="×">×</option>
                    <option value="÷">÷</option>
                  </select>
                </Select>
              </Control>
              <Control className="expandedWidth">
                <Input
                  className="borderedRight"
                  type="number"
                  min="0"
                  value={valueTwo}
                  onChange={e => setValueTwo(e.target.value)}
                />
              </Control>
            </Field>
            {isBinary(valueOne) && isBinary(valueTwo) ? (
              <>
                <p className="h5">
                  Binary value:
                  <br />
                  {`${valueOne} ${operatorOption} ${valueTwo} = ${calculateBinary(
                    valueOne,
                    valueTwo,
                    operatorOption
                  )}`}
                </p>
                <br />
                <p className="h5">
                  Decimal value:
                  <br />
                  {`${binaryToDecimal(
                    valueOne
                  )} ${operatorOption} ${binaryToDecimal(
                    valueTwo
                  )} = ${binaryToDecimal(
                    calculateBinary(valueOne, valueTwo, operatorOption)
                  )}`}
                </p>
              </>
            ) : (
              ""
            )}
            <Field>
              <Control>
                <StaticButton>Binary</StaticButton>
              </Control>
              <Control className="expandedWidth">
                <Input
                  radius="0"
                  type="number"
                  value={binaryToD}
                  onChange={convertBinaryToD}
                  onInput={convertBinaryToD}
                />
              </Control>
              <Control>
                <StaticButton bottomLeftRadius="0" TopLeftRadius="0">
                  To Decimal
                </StaticButton>
              </Control>
              <Control className="expandedWidth">
                <Input
                  className="borderedRight"
                  type="number"
                  value={decimalFromB}
                  readOnly
                />
              </Control>
            </Field>
            <Field>
              <Control>
                <StaticButton>Decimal</StaticButton>
              </Control>
              <Control className="expandedWidth">
                <Input
                  radius="0"
                  type="number"
                  value={decimaltoB}
                  onChange={convertDecimalToB}
                  onInput={convertDecimalToB}
                />
              </Control>
              <Control>
                <StaticButton bottomLeftRadius="0" TopLeftRadius="0">
                  To Binary
                </StaticButton>
              </Control>
              <Control className="expandedWidth">
                <Input
                  className="borderedRight"
                  type="number"
                  value={binaryFromD}
                  readOnly
                />
              </Control>
            </Field>
            <br />
            <h5>Binary Calculator</h5>
            <div
              style={{
                minHeight: "75px",
                border: "0.5px solid #666",
                borderRadius: "5px",
                width: "235px",
                overflow: "auto",
              }}
            >
              <p className="h4">
                {screen ? screen : firstValue + operator + secondValue}
              </p>
            </div>
            <br />
            <FlexDiv displayDiv="inline-flex" flexWrap="wrap">
              <KeyboardButton onClick={() => addNumber("0")}>0</KeyboardButton>
              <KeyboardButton onClick={() => addNumber("1")}>1</KeyboardButton>
              <InputButton
                type="button"
                background="linear-gradient(to top, #ea4336, red)"
                onClick={clearDisplay}
                value="C"
              />
              <KeyboardButton onClick={calculate}>=</KeyboardButton>
            </FlexDiv>
            <FlexDiv displayDiv="inline-flex" flexWrap="wrap">
              <KeyboardButton onClick={() => addOperator("+")}>
                +
              </KeyboardButton>
              <KeyboardButton onClick={() => addOperator("-")}>
                -
              </KeyboardButton>
              <KeyboardButton onClick={() => addOperator("×")}>
                &#215;
              </KeyboardButton>
              <KeyboardButton onClick={() => addOperator("÷")}>
                &#247;
              </KeyboardButton>
            </FlexDiv>
          </Column>

          <Column>
            {!isSSR && (
              <React.Suspense fallback={<div />}>
                <GoogleAds
                  slot="1189740279"
                  data-ad-format="auto"
                  data-ad-layout-key="-gi+g+7v-1q-6r"
                  data-full-width-responsive="true"
                />
              </React.Suspense>
            )}
          </Column>
        </Columns>

        <br />
        <h4>Binary Addition</h4>
        <p>
          Adding in the binary system:
          <br />
          0 + 0 = 0<br />
          0 + 1 = 1<br />
          1 + 0 = 1<br />1 + 1 = 0, carry over the 1, that is 10
        </p>
        <br />
        <h4>Binary Subtraction</h4>
        <p>
          subtraction in the binary system:
          <br />
          0 - 0 = 0<br />
          0 - 1 = 1<br />
          1 - 0 = 1<br />
          1 - 1 = 0<br />
        </p>

        <br />

        <h4>Binary Multiplication</h4>
        <p>
          Multiplication in the binary system:
          <br />
          0 × 0 = 0<br />
          0 × 1 = 0<br />
          1 × 0 = 0<br />
          1 × 1 = 1<br />
        </p>
        <br />
        {/* <h1 className="title fas mycolor">Binary Division</h1>
              <p>
                The process of binary division is similar to long division in
                the decimal system. The dividend is still divided by the divisor
                in the same manner, with the only significant difference being
                the use of binary rather than decimal subtraction. Note that a
                good understanding of binary subtraction is important for
                conducting binary division. Refer to the example below, as well
                as to the binary subtraction section for clarification.
              </p>
              <br /> */}
        <br />
        <SeeAlso seeAlsoList={seeAlsoArray} />
      </div>
      <br />
    </Layout>
  )
}

export default BinaryCalculator
